:root {
  /* colors */
  --milan-navy: #003542;
  --milan-blue: #0088ab;
  --milan-secondary-blue: #90d5d5;
  --milan-light-blue: #e4f5f4;
  --milan-turquoise: #00a5b4;
  /* --fallback-blue: #4DBBC7; */
  --milan-red: #f13a1a;
  --milan-white: #f9fbfb;
  --milan-off-white: #eff7f7;
  --milan-grey: #c2c2c2;
  /*added*/
  --milan-lt-grey: #efefef;
  /* end colors */
  --default-transition: all 150ms ease-in-out;
  --border-radius: 5px;
}

@import url("https://use.typekit.net/pvo2hea.css");
@import url('https://use.typekit.net/stc1fan.css');

body {
  font-family: "Open Sans", sans-serif;
  padding: 0;
  font-size: 16px;
  color: var(--milan-navy);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: brandon-grotesque, sans-serif;
  font-style: normal;
}

.hero h1.smalltitle,
.smalltitle {
  font-size: 2rem;
  /*added*/
  line-height: 2rem;
}

.milan-text-primary {
  color: var(--milan-blue) !important;
}

.milan-text-secondary {
  color: var(--milan-secondary-blue) !important;
}

.milan-text-navy {
  color: var(--milan-navy) !important;
}

.milan-text-tur {
  color: var(--milan-turquoise) !important;
}

.milan-text-red {
  color: var(--milan-red) !important;
}

h3.subpointlg,
h3.subpointsm>span {
  font-size: 1.5rem;
  line-height: 1.5rem;
  letter-spacing: -2px;
  font-weight: 500;
  text-transform: uppercase;
  margin: 0;
}

h3.subpointlg strong,
h3.subpointsm strong,
h3.subpointsm strong span {
  font-weight: 900;
  margin: 0;
}


@media screen and (min-width: 375px) {
  /************************************ || Typography || **************************************/
  /* | Headers | **********/
  h3.subpointlg,
  h3.subpointsm>span {
    font-size: 1.75rem;
    line-height: 2rem;
  }
}

@media screen and (min-width: 768px) {
  /************************************ || Typography || **************************************/
  /* | Headers | **********/
  h3.subpointlg,
  h3.subpointsm>span {
    font-size: 2.25rem;
    line-height: 2.25rem;
  }
}

@media screen and (min-width: 992px) {
  /************************************ || Typography || **************************************/
  /* | Headers | **********/
  h3.subpointlg,
  h3.subpointsm>span {
    font-size: 2.625rem;
    line-height: 2.625rem;
  }

  h3.subpointlg strong {
    font-weight: 900;
  }
}

@media screen and (min-width: 1200px) {
  /************************************ || Typography || **************************************/
  /* | Headers | **********/
  h3.subpointlg,
  h3.subpointsm>span {
    font-size: 3rem;
    line-height: 3rem;
  }
}


.milan-btn,
.milan-btn-sm {
  border: none;
  transition: var(--default-transition);
  border-radius: var(--border-radius);
  padding: 0.75rem 0;
  text-align: center;
  font-size: 1.125rem;
  display: inline-block;
  line-height: 1.5rem;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  cursor: pointer;
  text-decoration: none;
  /*added*/
  font-weight: 500;
  text-transform: capitalize;
}

/* get pre-qualified button on specials page is off-centered */
.milan-btn {
  width: 270px;
}

.milan-btn-sm {
  min-width: auto;
}

.milan-btn:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

@media screen and (min-width: 375px) {
  .milan-btn {
    width: 300px;
  }
}

@media screen and (min-width: 768px) {
  .milan-btn,
  .milan-btn-sm {
    padding: 0.75rem 1.5625rem;
    font-size: 1rem;
    line-height: 1.5rem;
    width: auto;
  }
}

@media screen and (min-width: 992px) {
  .milan-btn {
    padding: 0.5rem 1rem;
  }
}

@media screen and (min-width: 1200px) {
  .milan-btn {
    padding: 0.5rem 1.5625rem;
  }
}

.milan-cta-btn,
.milan-red-btn,
.milan-consult-btn {
  background-color: var(--milan-red);
  color: var(--milan-white);
}

.milan-cta-btn:hover,
.milan-red-btn:hover,
.milan-consult-btn:hover {
  background-color: var(--milan-navy);
  color: var(--milan-white);
}

.milan-red-btn:active,
.milan-cta-btn:active {
  background-color: var(--milan-navy);
  opacity: 0.7;
}

.milan-secondary-btn {
  background-color: var(--milan-secondary-blue);
  color: var(--milan-navy);
  font-weight: 800;
}

.milan-secondary-btn-selected {
  background-color: var(--milan-navy);
  color: var(--milan-white);

}

/* .milan-secondary-btn:hover {
  background-color: var(--milan-navy);
  color: var(--milan-white);
  font-weight: 800;
}

.milan-secondary-btn:active {
  background-color: var(--milan-navy);
}

.milan-secondary-btn:disabled {
  background-color: var(--milan-secondary-blue) !important;
  color: var(--milan-navy) !important;
} */



.p-subhead {
  font-size: 1.25rem;
  line-height: 1.375rem;
  font-weight: 400;
  text-transform: capitalize;
  margin-bottom: 0;
}

.p-subhead strong {
  font-weight: 700;
}


.milan-bg-navy {
  background-color: var(--milan-navy);
}

.milan-bg-primary {
  background-color: var(--milan-blue);
}

.milan-bg-secondary {
  background-color: var(--milan-secondary-blue);
}

.milan-bg-light-blue{
  background-color: var(--milan-light-blue);
}

.milan-bg-red {
  background-color: var(--milan-red);
}

.milan-bg-tur {
  background-color: var(--milan-turquoise);
}

.milan-bg-white {
  background-color: var(--milan-white);
}

.milan-bg-off-white {
  background-color: var(--milan-off-white);
}

.milan-border-radius {
  border-radius: var(--border-radius);
}


small,
.milan-fp {
  font-size: .75rem;
  /*added*/
  line-height: 1.125rem;
}



/*------------------------ CSS for CSS hehe get it? ok. ------------------------*/

/*---------- EmailVerify Section ----------*/
#check-email {
  background-image: url("./components/images/ClientSatisfactionHero.webp");
  background-size: cover;
}

@media screen and (max-width: 850px) {
  #check-email {
    background-size: cover;
    background-position: 38%;
  }
}


#check-email .form-control {
   color: var(--milan-navy);
   border: var(--bs-border-width) solid var(--milan-navy)
}

#check-email .form-control:focus {
   /* color: var(--milan-navy); */
   /* border: var(--bs-border-width) solid var(--milan-navy) */
   box-shadow: 0 0 0 0.05rem var(--milan-navy)
   
}

#check-email .form-control.errorBorderColor {
  border: var(--bs-border-width) solid var(--milan-red);
  box-shadow: var(--milan-red)
}

/*---------- Feedback Section ----------*/
#feedback .milan-btn.reccomendButton{
  padding: 0.25rem 1.941rem;
  /* font-weight: 700; */
  font-size: 1.5rem !important;
  height: 35px;
}

@media screen and (max-width: 850px) {
  #feedback .milan-btn.reccomendButton{
    padding: 0.25rem 1.241rem;
  }
}

#feedback .feedbackBtn{
  height: 88px;
  width: 100% !important;
}
@media screen and (max-width: 850px) {
  #feedback .feedbackBtn{
    height: 110px;
  }
}

#feedback .milan-white-btn {
  background-color: var(--milan-white);
  color: var(--milan-navy);
}
#feedback .milan-white-btn-selected {
  background-color: var(--milan-secondary-blue);
}



/*---------- result page ----------*/
#result {
  background-image: url("./components/images/ThankYouHero.webp");
  background-size: cover;
}

@media screen and (max-width: 850px) {
  #result {
    background-image: url("./components/images/ThankYouHeroVertical.webp");
  }
}


/* custom checkbox */
.custom-checkbox label {
  cursor: pointer;
  display: block;
  padding-left: 28px;
  margin-bottom: 0px;
  position: relative;
  -webkit-user-select: none;
  user-select: none;
  font-size: .9rem;
}

.custom-checkbox label input {
  cursor: pointer;
  height: 0;
  opacity: 0;
  position: absolute;
  width: 0;
}

.custom-checkbox .checkmark {
  /* background-color: var(--milan-off-white); */
  height: 18px;
  left: 0px;
  position: absolute;
  top: 1px;
  width: 18px;
  border-radius: 3px;
  border: 1px solid var(--milan-navy);
}

.custom-checkbox .checkmark:after {
  content: "";
  position: absolute;
  display: none;
  border: solid var(--milan-navy);
  border-width: 0 2px 2px 0;
  height: 10px;
  left: 6px;
  top: 2px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  width: 5px;
}

.custom-checkbox input:checked~.checkmark:after {
  display: block;
}